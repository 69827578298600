/* eslint-disable node/prefer-global/process */

import { isValue, valueToBoolean, valueToInteger } from 'zeed'
import type { AppConfig } from '@/_types/config'

let _env: any = {}
try {
  _env = import.meta.env
}
catch (err) { }
_env ??= process.env ?? {}

export const env = _env

export const DEVELOPMENT = env.MODE === 'development'
export const PRODUCTION = !DEVELOPMENT

let configNormalized: any

export function normalizeConfigName(name: string) {
  return name.toUpperCase().replace(/[-\ .]/gim, '_')
}

/**
 * There are multiple ways for configuration, listed by priority:
 *
 * 1. The server evaluates its ENV variables an passes them via
 *    URL `/briefing-config.js` to the server (loaded by `index.html` before
 *    all other code). Accessible through `window.briefingConfig`
 * 2. On build the ENV variables are passed via `import.meta.env`
 * 3. Default values
 */
export function getConfig(name: string, defaultValue?: string, forceUpdate = false) {
  if (forceUpdate || configNormalized == null) {
    const configEnv = Object.entries(env).map(([key, value]) => {
      key = normalizeConfigName(key)
      if (key.startsWith('BRIEFING_'))
        return [key, value]
      return undefined
    }).filter(isValue)

    const configWindow = Object
      .entries(globalThis.briefingConfig ?? {})
      .map(([key, value]) => ([`BRIEFING_${normalizeConfigName(key)}`, value]))

    configNormalized = Object.fromEntries([
      ...configEnv,
      ...configWindow,
    ])

    // console.log('configNormalized', configNormalized)
  }

  const _name = `BRIEFING_${normalizeConfigName(name)}`
  // console.log('getConfig', _name, (configNormalized[_name] ?? defaultValue))
  return (configNormalized[_name] ?? defaultValue)
}

export const appConfig: AppConfig = {
  getString(name, defaultValue) {
    return getConfig(name, defaultValue)
  },
  getBoolean(name, defaultValue) {
    return valueToBoolean(getConfig(name), defaultValue)
  },
  getInteger(name, defaultValue) {
    return valueToInteger(getConfig(name), defaultValue)
  },
}
