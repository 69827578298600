<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { OuiNotificationActivator } from 'oui-notification'
import { computed } from 'vue'
import { locale } from '@/lib/i18n'
import AppComponents from '@/components/app-components.vue'
import 'oui-notification/css'

import './app.styl'

const headInfo = computed(() => {
  const lang = locale.value
  return {
    htmlAttrs: { lang },
    templateParams: {
      site: {
        name: 'Briefing',
      },
      separator: '-',
    },
    titleTemplate: '%s %separator %site.name',
  }
})

useHead(headInfo)
</script>

<template>
  <router-view />
  <OuiNotificationActivator />
  <AppComponents placement="app-addition" />
</template>
