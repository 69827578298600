import { computed, markRaw } from 'vue'
import type { Router } from 'vue-router'
import type { DisposerFunction, EmitterSubscriberOptions, LoggerInterface } from 'zeed'
import { Logger, arrayRemoveElement, isArray, jsonStringify, sortedOrderby, uname, useDisposeWithUtils, useEventListener, uuid } from 'zeed'
import type { AppConfig } from '@/_types/config'
import type { AppEmitter, AppEmitterFunctions } from '@/_types/emitter'
import type { AppComponent, AppComponentPlacement, AppGlobalContext, AppPlugin, AppPluginClass, AppPluginContext } from '@/_types/plugin'
import { getAppContext } from '@/lib/context'

const log: LoggerInterface = Logger('plugin')

/** Description of a plugin's Vue3 component */
type AdditionalComponent = AppComponent & {
  /** A unique temporary identifier of the instance */
  id: string

  /** A unique temporary identifier of plugin type */
  pluginId: string

  /** The name of the plugin that added the component  */
  pluginName?: string
}

// const additionalComponents = reactive<Record<string, AdditionalComponent>>({})

const pluginRegistry: Record<string, AppPlugin> = {}

/** This is passed to each plugins setup function. It allows to deeply integrate with the app. */
export class AppContext implements AppPluginContext, AppGlobalContext {
  router: Router
  emitter: AppEmitter
  state: AppState
  config: AppConfig
  components: AppComponent[]

  currentPluginId = DEBUG ? uname('plugin') : uuid()
  currentPluginName: string

  dispose = useDisposeWithUtils()

  constructor(app: AppGlobalContext, plugin: AppPlugin) {
    // log('app', app)
    this.router = app.router
    this.emitter = app.emitter
    this.state = app.state
    this.config = app.config
    this.currentPluginName = plugin.name
    this.components = app.components

    log.assert(isArray(this.components), 'app.components expected')
  }

  /** Add a Vue3 component. Some areas are predefined. */
  addComponent(info: AppComponent): void {
    const id = DEBUG ? uname('component') : uuid()
    const addComponent: AdditionalComponent = {
      id,
      pluginId: this.currentPluginId,
      pluginName: this.currentPluginName,
      sortWeight: 0,
      // title: t(`${this.currentPluginName ?? 'unknown'}.title`),
      ...info,
      component: markRaw(info.component),
    }
    this.components.push(addComponent)
    this.dispose.add(() => arrayRemoveElement(this.components, addComponent))
  }

  on<U extends keyof AppEmitterFunctions>(event: U, listener: AppEmitterFunctions[U], opt?: EmitterSubscriberOptions): DisposerFunction {
    return this.dispose.add(useEventListener(this.emitter, event, listener, opt))!
  }

  hasPlugin(name: string) {
    return pluginRegistry[name] != null
  }
}

function _registerPlugin(PluginClass: AppPluginClass) {
  try {
    const plugin = new PluginClass()
    if (plugin == null)
      return log.error('Plugin is empty', PluginClass)
    const name = plugin.name ?? uname('plugin')
    // log('registerPlugin', name)
    if (pluginRegistry[name] != null)
      return log.error(`Plugin ${jsonStringify(name)} has already been registered!`, plugin)
    pluginRegistry[name] = plugin
  }
  catch (err) {
    log.error(`Instantiation of plugin ${PluginClass} did not succeed:`, err)
  }
}

async function _setupPlugin(plugin: AppPlugin, app: AppGlobalContext) {
  if (plugin.requires) {
    for (const r of plugin.requires) {
      if (pluginRegistry[r] == null)
        log.error(`Requirement ${r} for plugin ${plugin.name} is not satisfied!`)
    }
  }
  try {
    log.info(`Setup of plugin ${plugin.name}`)
    const context = new AppContext(app, plugin)
    await plugin.setup(context)
  }
  catch (err) {
    log.error(`Setup of plugin ${plugin.name} did not succeed:`, err)
  }
}

/** Make plugins available. They themselves register for visuals and features. */
export async function setupPlugins(pluginClasses: AppPluginClass[], app: AppGlobalContext) {
  // Step 1: Instances
  for (const p of pluginClasses)
    _registerPlugin(p)

  // Step 1: Setup
  for (const p of Object.values(pluginRegistry))
    await _setupPlugin(p, app)
}

/** Vue computed list of components for a certain placement */
export function computedComponents(placement: AppComponentPlacement, orderby: string[] = ['sortWeight', 'title']) {
  const app = getAppContext()
  // log('computedComponents app', cloneJsonObject(app), placement, orderby)
  return computed<AppComponent[]>(() => {
    return sortedOrderby(app.components.filter(c => c.placement === placement), ...orderby)
  })
}
