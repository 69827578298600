import { getWebsocketUrlFromLocation } from '@zerva/websocket'
import { getNavigator, valueToBoolean } from 'zeed'
import { DEVELOPMENT, PRODUCTION, appConfig, env, getConfig } from '@/config-base'

export const isAppleMobile = getNavigator()?.platform?.match(/(iPhone|iPod|iPad)/i) != null
export const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(getNavigator()?.userAgent)

// export const iPhone = navigator?.platform?.match(/(iPhone|iPod)/i) != null // Identified Phone of a native app

// See https://github.com/holtwick/briefing-signal
export const SIGNAL_SERVER_URL = getConfig('SIGNAL_URL', getWebsocketUrlFromLocation('/rpc'))

// getConfig('STUN_URL', 'stun:turn01.brie.fi:5349')
// iceServers: [
//   {
//     urls: [
//       'stun:stun.l.google.com:19302',
//       'stun:global.stun.twilio.com:3478'
//     ]
//   }
// ],
// sdpSemantics: 'unified-plan'

const STUN_PORT = getConfig('STUN_PORT', '3478')
const STUN_URL = getConfig('STUN_URL', `stun:${location.hostname}:${STUN_PORT}`)

const iceServers: RTCIceServer[] = [{
  urls: STUN_URL,
}]

const TURN_URL = getConfig('TURN_URL') // , 'turn:turn01.brie.fi:5349')
if (TURN_URL) {
  iceServers.push({
    urls: TURN_URL,
    username: getConfig('TURN_USER', 'brie'),
    credential: getConfig('TURN_PASSWORD', 'fi'),
  })
}

// See https://github.com/feross/simple-peer#peer--new-peeropts
export const ICE_CONFIG: RTCConfiguration = {
  iceTransportPolicy: 'all',

  // @ts-expect-error TODO has effect?
  reconnectTimer: 3000,

  // These settings are no secret, since they are readable from the client side anyway
  iceServers,
}

export const RELEASE = env.BRIEFING_RELEASE

export const SENTRY_DSN = getConfig('SENTRY_DSN')

export const ROOM_PATH = getConfig('ROOM_PATH', '/')
export const ROOM_URL = getConfig('ROOM_URL', `${location.protocol}//${location.host}${ROOM_PATH}`)
export const ROOM_DOMAIN = getConfig('ROOM_DOMAIN', location.hostname)

export const SHOW_FULLSCREEN = valueToBoolean (getConfig('SHOW_FULLSCREEN'), true)
export const SHOW_INVITATION = valueToBoolean(getConfig('SHOW_INVITATION'), true)
export const SHOW_INVITATION_HINT = valueToBoolean(getConfig('SHOW_INVITATION_HINT'), true)
export const SHOW_SETTINGS = valueToBoolean(getConfig('SHOW_SETTINGS'), true)
export const SHOW_SHARE = valueToBoolean(getConfig('SHOW_SHARE'), true)
export const SHOW_CHAT = valueToBoolean(getConfig('SHOW_CHAT'), true)

// export const USE_SIDEBAR_TABS = valueToBoolean(getConfig('USE_SIDEBAR_TABS'), false)

export const MUTE_AUDIO = valueToBoolean(getConfig('MUTE_AUDIO'), false)
export const MUTE_VIDEO = valueToBoolean(getConfig('MUTE_VIDEO'), false)

export const DEFAULT_ROOM = getConfig('DEFAULT_ROOM')
export const DEFAULT_SIDEBAR = getConfig('DEFAULT_SIDEBAR', '')

export const LICENSE = getConfig('LICENSE', '')
export const SUPPORT_EMAIL = getConfig('SUPPORT_EMAIL', 'support@holtwick.de')

/** Blend in screenshots for marketing */
// export const SCREENSHOTS = valueToBoolean(getConfig('SCREENSHOTS'), false)

export const _ENV = valueToBoolean(getConfig('ENV'), false)

export const config = {
  _ENV,

  DEVELOPMENT,
  PRODUCTION,
  RELEASE,

  DEFAULT_ROOM,
  DEFAULT_SIDEBAR,

  ICE_CONFIG,
  LICENSE,
  MUTE_AUDIO,
  MUTE_VIDEO,
  ROOM_DOMAIN,
  ROOM_PATH,
  ROOM_URL,
  SENTRY_DSN,
  SHOW_CHAT,
  SHOW_FULLSCREEN,
  SHOW_INVITATION_HINT,
  SHOW_INVITATION,
  SHOW_SETTINGS,
  SHOW_SHARE,
  SIGNAL_SERVER_URL,

  /** share: */
  SUPPORT_EMAIL,

  // USE_SIDEBAR_TABS,
  // NOTIFICATIONS_DEBUG: valueToBoolean(getConfig('NOTIFICATIONS_DEBUG'), false),
  // NOTIFICATIONS_LIMIT: valueToInteger(getConfig('NOTIFICATIONS_LIMIT'), 6),
  // SCREENSHOTS,
}

// log.info(`Config: ${jsonStringifySorted(config, 2)}`)

export { DEVELOPMENT, PRODUCTION, appConfig, getConfig }
