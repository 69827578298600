import { inject } from 'vue'
import type { LoggerInterface } from 'zeed'
import { Logger } from 'zeed'
import type { AppGlobalContext } from '@/_types/plugin'

const log: LoggerInterface = Logger('plugin:context')

/** Get the app context from within a Vue component. Shortcut to the equivalent `inject` call, but with safety net. */
export function getAppContext() {
  const context = inject<AppGlobalContext>('app')
  log.assert(context != null, 'expected AppGlobalContext to exist. Are you sure to be calling from within a Vue component?')
  return context
}
