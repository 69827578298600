import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import type { LoggerInterface } from 'zeed'
import { Logger } from 'zeed'
import { state } from '@/state'
import { ROOM_PATH } from '@/config'

const log: LoggerInterface = Logger('app:router')

let roomPath = ROOM_PATH
if (roomPath.endsWith('/'))
  roomPath = roomPath.slice(0, -1)

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect(to) {
      return ({ name: 'start' })
    },
  },

  {
    path: roomPath,
    name: 'start',
    component: () => import('./components/app-start.vue'),
  },

  {
    path: `${roomPath}/:roomName`,
    name: 'room',
    component: () => import('./components/app-room.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('./components/app-start.vue'),
  },
]

export const router = createRouter({
  routes,
  history: createWebHistory(),
})

router.beforeEach(async (to, from, next) => {
  log.info(`before route to=${to?.fullPath} from=${from?.fullPath}`)

  // App mode special
  document.documentElement.classList.remove('app-mode')
  if (!to.meta.scroll)
    document.documentElement.classList.add('app-mode')

  // We want to make sure the whole room related things
  // are ready BEFORE the vue components fire up

  const rm = state.roomManager
  log.assert(rm, 'roomManager required')

  if (from.name === 'room')
    await rm.roomLeave()
  if (to.name === 'room')
    await rm.roomEnter(String(to.params.roomName))

  next()
})
