<script lang="ts" setup>
import { computedComponents } from '@/lib/plugin'
import type { AppComponentPlacement } from '@/_types/plugin'

const props = defineProps<{
  placement: AppComponentPlacement
  single?: boolean
  allowEmpty?: boolean
  data?: any
}>()

const componentsInfo = computedComponents(props.placement)
</script>

<template>
  <template v-if="props.single">
    <template v-if="componentsInfo.length > 1">
      You may only define ONE component for {{ placement }}. Instead found {{ componentsInfo.length }}.
    </template>
    <template v-else-if="componentsInfo.length === 0">
      <slot>
        <template v-if="allowEmpty !== true">
          No component for {{ placement }} defined. You need to add one or define a slot.
        </template>
      </slot>
    </template>
    <template v-else>
      <Suspense>
        <component :is="componentsInfo[0].component" :value="componentsInfo[0].value" :data="data" />
      </Suspense>
    </template>
  </template>
  <template v-else>
    <template v-if="componentsInfo.length > 0">
      <template v-for="c in componentsInfo" :key="c">
        <Suspense>
          <component :is="c.component" :value="c.value" :data="data" />
        </Suspense>
      </template>
    </template>
    <template v-else>
      <slot />
    </template>
  </template>
</template>
