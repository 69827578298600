import { markRaw, reactive } from 'vue'
import type { LoggerInterface, UseRPCHubType } from 'zeed'
import { Emitter, Logger, decodeJson, encodeBase32, encodeJson, size, useDisposeWithUtils, useRPCHub } from 'zeed'
import { getFingerprintString, sha256Messages, splitByNChars } from '../../../lib/network/webrtc-fingerprint'
import type { MessageType, WebRTCPeerOptions } from '../../../lib/network/webrtc-peer'
import { WebRTCPeer } from '../../../lib/network/webrtc-peer'
import type { PeerStream, RoomVisitor, RoomVisitorEvents } from '@/_types/room-visitor'

const PORT_PEER = 1
const PORT_STATUS = 2

export function isSupportedWebRTC() {
  return WebRTCPeer.WEBRTC_SUPPORT
}

/** Others would probably call it "participant". But it is different to "peer" which we only use to WebRTC related stuff! */
export class RoomVisitorInstance extends Emitter<RoomVisitorEvents> implements RoomVisitor {
  // public visitorId: string
  public readonly peerId: string
  public readonly rpcHubVisitor: UseRPCHubType
  public fingerprint: string
  public dispose = useDisposeWithUtils()
  public stream?: MediaStream
  public streamsRemote: Record<string, PeerStream> = reactive({}) // todo reactive at this low level?

  private log: LoggerInterface
  private active: boolean = false
  private closed: boolean = false
  private room: string
  private initiator: boolean
  private webrtcPeer?: WebRTCPeer
  private rpcDataHandler?: (data: any) => void
  private bufferForPostMessage: Uint8Array[] = []

  constructor({ peerId, initiator, room, opts }: {
    room: string
    peerId: string
    // visitorId: string
    initiator: boolean
    opts: WebRTCPeerOptions
  }) {
    super()

    // this.visitorId = visitorId
    this.log = Logger(`room:v:${peerId}`)
    this.log('setup peer')

    this.peerId = peerId
    this.initiator = initiator
    this.room = room
    this.fingerprint = ''

    // RPC hub to coomunicate with peers via WebRTC Data Channel
    this.rpcHubVisitor = useRPCHub({
      onlyEvents: true,
      post: (data: any) => {
        try {
          this.postMessage(data, PORT_PEER)
        }
        catch (err) {
          this.log.warn('rpc postMessage', err, data)
        }
      },
      on: (handler: any) => this.rpcDataHandler = handler,
      serialize: encodeJson,
      deserialize: decodeJson,
    })

    /*
     this.on('peerDataInternal', (data) => {
        try {
          return handler(data)
        }
        catch (err) {
          this.log.warn('rpc channel.on', err, data)
        }
      } */

    this.setupPeer(opts)
  }

  setupPeer(opt: WebRTCPeerOptions) {
    this.active = false
    this.stream = undefined

    // do not clone! may contain functions and the like
    const opts: WebRTCPeerOptions = {
      ...opt,

      peerId: this.peerId,
      initiator: this.initiator,

      // Allow the peer to receive video, even if it's not sending stream: https://github.com/feross/simple-peer/issues/95
      offerOptions: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      },
    }

    // this.log('Peer opts:', opts, opt)

    // https://github.com/feross/simple-peer/blob/master/README.md
    this.webrtcPeer = new WebRTCPeer(opts)

    this.webrtcPeer.on('disconnect', this.close.bind(this)) // ???
    this.webrtcPeer.on('close', this.close.bind(this))

    // We receive a connection error
    this.webrtcPeer.on('error', async (err) => {
      this.log('error', err)
      void this.emit('peerError', err)
      await this.close()
      setTimeout(() => {
        this.setupPeer(opt) // ??? retry ?
      }, 1000)
    })

    // This means, we received network details (signal) we need to provide
    // the remote peer, so he can set up a connection to us. Usually we will
    // send this over a separate channel like the web socket signaling server
    this.webrtcPeer.on('signal', (data) => {
      // this.log(`signal`, this.initiator)
      void this.emit('peerSignal', data)
    })

    this.webrtcPeer.on('signalingStateChange', async (_) => {
      const fpl = getFingerprintString(this.webrtcPeer?.connection?.currentLocalDescription?.sdp ?? '')
      const fpr = getFingerprintString(this.webrtcPeer?.connection?.currentRemoteDescription?.sdp ?? '')
      if (fpl && fpr) {
        const digest = await sha256Messages(this.room, fpl, fpr)
        this.fingerprint = splitByNChars(encodeBase32(digest), 4)
      }
      else {
        this.fingerprint = ''
      }
    })

    // We received data from the peer
    this.webrtcPeer.on('data', (data) => {
      if (!this.active)
        this.log.warn('data receivedbefore connect', data)
      const port = data[0]
      const actualData = data.subarray(1)
      if (port === PORT_PEER) {
        if (this.rpcDataHandler)
          this.rpcDataHandler(actualData)
        else
          this.log.error('rpc handler not set')
        // void this.emit('peerDataInternal', actualData, port)
      }
      else if (port === PORT_STATUS) {
        if (actualData[0] === 1) {
          this.log('handshake echo')
          this.postMessage(new Uint8Array([2]), PORT_STATUS)
        }
        else if (actualData[0] === 2) {
          this.log('handshake finished')
          void this.emit('peerReady')
        }
        else {
          this.log.error('unexpected actualData', actualData)
        }
      }
      else {
        void this.emit('peerData', actualData, port)
      }
    })

    this.webrtcPeer.on('ready', async () => {
      this.log('peer ready')
    })

    // Connection succeeded
    this.webrtcPeer.on('connect', async (streamInfo) => {
      this.log('peer connect')

      this.log.assert('expected connection')
      this.dispose.on(this.webrtcPeer?.connection, 'datachannel', event => void this.emit('peerDataChannel', event.channel))

      this.active = true
      await this.emit('peerConnect')
      this.postMessage(new Uint8Array([1]), PORT_STATUS)

      // Object.values(streamInfo).forEach((streamInfo) => {
      //   if (streamInfo.stream)
      //     this.webrtcPeer?.emit('stream', streamInfo.stream, streamInfo.name)
      // })
    })

    this.webrtcPeer.on('streamRemove', (stream, name) => {
      this.log('streamRemove', name)
      if (name)
        delete this.streamsRemote[name]
      void this.emit('peerStream', undefined, name)
    })

    this.webrtcPeer.on('stream', (stream, name) => {
      this.log('stream', name) // stream, Object.keys(this.streamsRemote))
      this.streamsRemote[name] = { name, stream: markRaw(stream) }
      if (name !== 'desktop')
        this.stream = stream
      void this.emit('peerStream', stream, name)
    })
  }

  setStreamLocal(stream?: MediaStream, name?: string) {
    this.log(`stream local ${name}`)
    try {
      this.webrtcPeer?.setStream(stream, name)
    }
    catch (err: any) {
      this.log.error('setStream', err)
    }
  }

  // We got a signal from the remote peer and will use it now to establish the connection
  signal(data: MessageType) {
    if (this.webrtcPeer && !this.webrtcPeer.destroyed) {
      // To prove that manipulated fingerprints will result in refusing connection
      // if (data?.sdp) {
      //   data.sdp = data.sdp.replace(/(fingerprint:.*?):(\w\w):/, '$1:00:')
      // }
      this.webrtcPeer.signal(data as any) // todo typing!
    }
    else {
      this.log('Tried to set signal on destroyed peer', this.webrtcPeer, data)
    }
  }

  _send(data?: Uint8Array) {
    if (this.webrtcPeer && data != null && size(data) > 0)
      this.webrtcPeer.send(data)
  }

  postMessage(data?: Uint8Array, port?: number) {
    if (data && port != null) {
      const newData = new Uint8Array(data?.length + 1)
      newData.set([port], 0)
      newData.set(data, 1)
      data = newData
    }
    else if (data) {
      this.log.warn('missing port!')
    }
    if (this.active && this.webrtcPeer) {
      while (this.bufferForPostMessage.length)
        this._send(this.bufferForPostMessage.shift())
      this._send(data)
    }
    else {
      this.log('buffer')
      if (data != null && size(data) > 0)
        this.bufferForPostMessage.push(data)
    }
  }

  negotiateBandwith() { // todo ????
    this.webrtcPeer?.negotiate()
  }

  public createDataChannel(label: string, dataChannelDict?: RTCDataChannelInit): RTCDataChannel | undefined {
    return this.webrtcPeer?.connection?.createDataChannel(label, dataChannelDict)
  }

  // async getStats() { // todo ????
  //   let bytes = 0
  //   let timestamp = 0
  //   return new Promise((resolve) => {
  //     this.WebRTCPeer?.getStats((_: any, reports: any[]) => {
  //       this.log(_, reports)
  //       reports.forEach((report) => {
  //         if (report.type === 'outbound-rtp') {
  //           if (report.isRemote)
  //             return
  //           bytes += report.bytesSent
  //           timestamp = report.timestamp
  //           // debug('bb', bytes, prevBytes, timestamp, prevTimestamp)
  //           resolve({ bytes, timestamp })
  //         }
  //       })
  //     })
  //   })
  // }

  public async close() {
    if (this.closed) {
      await this.dispose()
      this.closed = true
      void this.emit('peerClose')
      this.webrtcPeer?.destroy()
      this.active = false
      this.stream = undefined
      this.webrtcPeer = undefined
    }
  }
}
