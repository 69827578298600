import { reactive } from 'vue'
import { DEFAULT_SIDEBAR } from './config'
import { isSupportedWebRTC } from '@/plugins/room/client/room-visitor'

export const state = reactive<AppState>({
  room: undefined,
  visitors: {},

  sidebarMode: DEFAULT_SIDEBAR,
  isSupported: isSupportedWebRTC(), // todo really required?

  // depr
  fill: true,
  maximized: '',

  media: {
    audioMute: false,
    videoMute: false,
    videoFlip: true,
    devices: [],
  },
})
