import type { AppPluginClass } from '@/_types/plugin'

const pluginsMap = Object.fromEntries(
  Object.entries(import.meta.glob('./*/index-client.ts')).map(([path, loadLocale]) => [path.match(/\/([\w-]*)\/index-/)?.[1], loadLocale]),
) as Record<string, () => Promise<{ default: AppPluginClass }>>

export const plugins = [
  'media',
  'room',
  'share',
  'stage',
]

async function loadPlugin(name: string) {
  if (!pluginsMap[name])
    console.warn(`missing plugin ${name}}`)
  return (await pluginsMap[name]()).default
}

export async function getPlugins() {
  return await Promise.all(plugins.map(loadPlugin))
}

// Unregister any previous PWA

// if (window.navigator && navigator.serviceWorker) {
//   navigator.serviceWorker.getRegistrations()
//     .then((registrations) => {
//       for (const registration of registrations)
//         registration.unregister()
//     })
// }
