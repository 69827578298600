import { createHead } from '@unhead/vue'
import { createApp, markRaw, reactive, readonly } from 'vue'
import { Logger, useDisposeWithUtils } from 'zeed'
import appComponent from './components/app.vue'
import type { AppComponent, AppGlobalContext } from '@/_types/plugin'
import { appConfig } from '@/config'
import { i18n } from '@/lib/i18n'
import { setupPlugins } from '@/lib/plugin'
import { globalEmitter as emitter } from '@/lib/utils/global-emitter'
import { getPlugins } from '@/plugins/plugins-client'
import { router } from '@/router'
import { state } from '@/state'

const log = Logger('main')

// log(`env = ${JSON.stringify(import.meta.env, null, 2)}`)

async function main() {
  const dispose = useDisposeWithUtils()

  const components: AppComponent[] = []

  const appGlobalContext: AppGlobalContext = {
    emitter,
    router,
    state: state as any, // any required, because some global additions may cause irritations
    components,
    config: appConfig,
    dispose, // todo
  }

  // todo does this make sense?

  emitter.on('uiToggleSidebar', (name) => {
    state.sidebarMode = (state.sidebarMode !== name && name) || ''
  })

  //

  log.info('setup plugins')

  const plugins = await getPlugins()
  await setupPlugins(plugins, appGlobalContext)

  // Setup Vue app
  log.info('create app')
  const app = createApp(appComponent)

  log.info('provide app')
  app.provide('app', reactive({
    state,
    config: readonly(markRaw(appConfig)),
    emitter: readonly(markRaw(emitter)),
    components: readonly(markRaw(components)),
    // router: readonly(markRaw(router)),
  }))

  log.info('use vue modules')
  app.use(createHead())
  app.use(router)
  app.use(i18n)

  log.info('app will mount')
  await emitter.emit('appWillMount', app)

  log.info('app mount')
  app.mount('#app')

  // Sometimes it takes a bit longer... remove loading animation
  const spinner = document.getElementById('app-spinner')
  if (spinner)
    spinner.parentElement?.removeChild(spinner)
}

void main()
